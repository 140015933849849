/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import getConfig from "next/config";
import { slugify } from "@themuse/design-system";
import { triggerSnowplowClickEvent } from "@themuse/snowplow-js-client";
import { useAppState } from "hooks/AppContext";
import {
    careerTypeMapping,
    jobCategoryItems
} from "utils/constants";
import { ContentModuleBaseAttributes } from "utils/orm";

import styles from "./trendingSearch.module.scss";

/**
 * Retrieves the details of a job category based on the provided key.
 */
const getCategoryDetails = (key: string) => jobCategoryItems.filter((x) => x.label === key).pop();

/**
 * Component for displaying the grouping of Stars.
 */
const Stars = () => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 63 47">
        <g clipPath="url(#a)">
            <path
                fill="#1D2D5A"
                d="M11.771 1.978h-.092A11.742 11.742 0 0 1 .367 12.758v.02A11.745 11.745 0 0 1 11.73 24.515a11.747 11.747 0 0 1 11.747-11.748A11.745 11.745 0 0 1 11.77 1.978Z"
            />
            <path
                fill="#F69576"
                d="M23.635 20.9h-.105a13.372 13.372 0 0 1-12.882 12.275v.024a13.375 13.375 0 0 1 12.94 13.365 13.377 13.377 0 0 1 13.377-13.377 13.375 13.375 0 0 1-13.33-12.288Z"
            />
            <path
                fill="#1D2D5A"
                d="M44.19.457h-.145a18.475 18.475 0 0 1-17.799 16.96v.034a18.481 18.481 0 0 1 17.878 18.467 18.484 18.484 0 0 1 18.484-18.484A18.48 18.48 0 0 1 44.19.457Z"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h63v47H0z" />
            </clipPath>
        </defs>
    </svg>
);

/**
 * An object of snowplows impression event.
 */
const impressionTracking = {
    sp_impression_component: "insert",
    sp_impression_insert_type: "trending_searches",
    sp_impression_position: "in_article",
};

/**
 * Returns an object of snowplows click event.
 */
const getClickTracking = (linkText: string, position: string) => ({
    sp_click_component: "insert",
    sp_click_insert_type: "trending_searches",
    sp_click_position: position ?? "in_article",
    sp_click_link_text: linkText,
    sp_click_target_page_section: "search",
    sp_tile_entity_id: null,
    sp_tile_type: null
});

export interface TrendingSearchBlock extends ContentModuleBaseAttributes {}
interface TrendingAnchorProps {
    value: string,
    jobLocation: string,
    tracking: any,
    position: number,
    linkText: string;
}

/**
 * Functional component for rendering a trending anchor link.
 */
const TrendingAnchor = (props: TrendingAnchorProps) => {
    const {
        value, jobLocation, tracking, position, linkText
    } = props;
    const { publicRuntimeConfig: { museHost } } = getConfig();
    return (
        <a
            key={value}
            className={styles.searchItem}
            href={`${museHost}/search/${jobLocation}category/${value}/`}
            onClick={() => triggerSnowplowClickEvent(tracking)}
            target="_blank"
            rel="noopener"
        >
            <span className={styles.searchNumber}>{`${position}.`}</span>
            <span className={styles.searchText}>{linkText}</span>
        </a>
    );
};
TrendingAnchor.displayName = "TrendingAnchor";

/**
 * Functional component for displaying trending searches module.
 * * The blocks are broken up so that numbering does not bounce from side to side.
 */
export const TrendingSearch: React.FC<TrendingSearchBlock> = () => {
    const { publicRuntimeConfig: { museHost } } = getConfig();
    const { article, user } = useAppState();
    const { careerTypes = [] } = article;
    const { location: { dma } } = user;
    const jobLocation = dma ? `location/${slugify(dma)}/` : "";
    const careerTags = careerTypes.map((x) => x.title);
    const activeCareer = Object.keys(careerTypeMapping).find((career) => careerTags.includes(career)) || "Default";
    const heading = dma ? `Trending Searches in ${dma}` : "Trending Searches";
    const split = Math.round(careerTypeMapping[activeCareer].length / 2);
    const left = careerTypeMapping[activeCareer].slice(0, split);
    const right = careerTypeMapping[activeCareer].slice(split);
    const [contentPosition, setContentPosition] = useState<string>("in_article");
    const impression = {
        ...impressionTracking,
        sp_impression_position: contentPosition
    };

    useEffect(() => {
        if (!window.location.href.includes("/advice")) {
            setContentPosition("main");
        }
    }, []);

    return (
        <aside
            className={styles.trendingSearch}
            data-tracking-props={JSON.stringify(impression)}
            data-tracking-event-type="impression"
        >
            <header>
                <h2>{heading}</h2>
                <Stars />
            </header>
            <div className={styles.row}>
                <div className={styles.column}>
                    {left.map((career: string, index: number) => {
                        const position = index + 1;
                        const { label, value } = getCategoryDetails(career);
                        const linkText = `${label} Jobs`;
                        const tracking = getClickTracking(linkText, contentPosition);
                        return (
                            <TrendingAnchor
                                {...{
                                    value, jobLocation, tracking, position, linkText
                                }}
                                key={value}
                            />
                        );
                    })}
                </div>
                <div className={styles.column}>
                    {right.map((career: string, index: number) => {
                        const position = index + split + 1;
                        const { label, value } = getCategoryDetails(career);
                        const linkText = `${label} Jobs`;
                        const tracking = getClickTracking(linkText, contentPosition);
                        return (
                            <TrendingAnchor
                                {...{
                                    value, jobLocation, tracking, position, linkText
                                }}
                                key={value}
                            />
                        );
                    })}
                </div>
            </div>
            <a
                className={styles.searchAllJobs}
                href={`${museHost}/search/`}
                onClick={() => triggerSnowplowClickEvent(getClickTracking("SEARCH ALL JOBS", contentPosition))}
                target="_blank"
                rel="noopener"
            >
                SEARCH ALL JOBS
            </a>
        </aside>
    );
};
TrendingSearch.displayName = "TrendingSearch";
